import { useIntl } from 'react-intl';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import { STREET_CODE_TYPES } from 'constants/bosta-coding';

import BRHeader from 'components/BRHeader/BRHeader';
import Container from 'components/Container/index';
import BRTabs from 'components/BRTabs/BRTabs';
import ZonesGeofencing from 'components/BostaCoding/components/ZonesGeofencing/ZonesGeofencing';
import CodesGeofencing from 'components/BostaCoding/components/CodesGeofencing/CodesGeofencing';
import DistrictsMapping from 'components/BostaCoding/components/DistrictsMapping/DistrictsMapping';

import './BostaCoding.less';

const DEFAULT_TAB_PATH = 'zones-geofencing';

const BostaCoding = ({ match: { path } }) => {
  const intl = useIntl();

  const TABS = {
    zonesGeofencing: {
      label: intl.formatMessage({
        id: 'bosta_coding.zones_geofencing.title'
      }),
      component: ZonesGeofencing,
      path: '/hubs/bosta-coding/zones-geofencing'
    },
    codesGeofencing: {
      label: intl.formatMessage({ id: 'bosta_coding.codes_geofencing.title' }),
      component: CodesGeofencing,
      componentProps: {
        type: STREET_CODE_TYPES.LAST_MILE
      },
      path: '/hubs/bosta-coding/codes-geofencing'
    },
    fmCodesGeofencing: {
      label: intl.formatMessage({
        id: 'bosta_coding.fm_codes_geofencing.title'
      }),
      component: CodesGeofencing,
      componentProps: {
        type: STREET_CODE_TYPES.FIRST_MILE
      },
      path: '/hubs/bosta-coding/fm-codes-geofencing'
    },
    districtsMapping: {
      label: intl.formatMessage({
        id: 'bosta_coding.district_mapping.title'
      }),
      component: DistrictsMapping,
      path: '/hubs/bosta-coding/districts-mapping'
    }
  };

  return (
    <Switch>
      <Route
        path={Object.keys(TABS).map((tab) => TABS[tab].path)}
        exact
        render={() => (
          <Container
            className="br-bosta-coding__container"
            header={
              <BRHeader
                title={intl.formatMessage({ id: 'sidebar.bosta_coding' })}
              />
            }
            content={<BRTabs tabs={TABS} />}
          />
        )}
      />
      <Redirect from={path} to={`${path}/${DEFAULT_TAB_PATH}`} />
    </Switch>
  );
};

export default withRouter(BostaCoding);
