import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import {
  BOSTA_ANSWERS,
  BOSTA_MESSAGE,
  CONSIGNEE_ANSWERS,
  CONSIGNEE_MESSAGE,
  WHATS_APP_DATE_FORMAT,
  WHATS_APP_RESCHUDLE_FORMAT,
  WHATS_APP_STATUS
} from 'constants/whats-app';
import { getWhatsAppConversation } from 'services/shipments';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as Bosta } from 'assets/bosta-icons/bosta.svg';
import { ReactComponent as User } from 'assets/bosta-icons/user.svg';
import { ReactComponent as DoubleCheck } from 'assets/bosta-icons/Double check.svg';
import { ReactComponent as Check } from 'assets/bosta-icons/Check.svg';

import './WhatsAppLogs.less';

const WhatsAppLogs = ({
  whatsAppLastMileActions,
  trackingNumber,
  whatsAppEvents
}) => {
  const intl = useIntl();
  const [allLogs, setAllLogs] = useState([]);

  useEffect(() => {
    if (whatsAppEvents.length) {
      setAllLogs(constructChat(whatsAppEvents));
    }
  }, [whatsAppEvents]);

  const renderSentIcon = (status) => {
    switch (status) {
      case WHATS_APP_STATUS.DELIVERIED:
        return (
          <div className="iconWrapper-delivered">
            <DoubleCheck />
          </div>
        );

      case WHATS_APP_STATUS.READ:
        return (
          <div className="iconWrapper-read">
            <DoubleCheck />
          </div>
        );
      case WHATS_APP_STATUS.SENT:
        return (
          <div className="iconWrapper-read">
            <Check />
          </div>
        );
      default:
        return <></>;
    }
  };

  const mapLink = (lat, lng) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${lng},${lat}`;

    return (
      <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
        {intl.formatMessage({
          id: 'deliveries.delivery_details.whats_app.location_sent'
        })}
      </a>
    );
  };

  const mapBostaMessages = (eventName) => {
    return BOSTA_ANSWERS[eventName];
  };

  const MapConsigneeAnswers = (eventName) => {
    return CONSIGNEE_ANSWERS[eventName];
  };

  const constructConsgineeEventsMessages = (events) => {
    let logs = [];
    events?.map((event) => {
      let message = MapConsigneeAnswers(event?.eventName);
      if (message) {
        logs.push({
          messageType: CONSIGNEE_MESSAGE,
          message,
          timeStamp: dayjs(event?.eventTime),
          status: event?.status
        });
      }
    });
    return logs;
  };

  const constructBostaMessages = (events) => {
    const BostaLogs = [];
    events?.map((event) => {
      let message = mapBostaMessages(event?.eventName);
      if (message) {
        BostaLogs.push({
          messageType: BOSTA_MESSAGE,
          message,
          timeStamp: dayjs(event?.eventTime),
          status: event?.status
        });
      }
    });
    return BostaLogs;
  };

  const getLocationTimeStamp = (events) => {
    const targetEvent = events?.find(
      (event) => event?.eventName === 'CONSIGNEE_SENT_CONFIRMED_GEOLOCATION'
    );
    return targetEvent ? dayjs(targetEvent.eventTime) : undefined;
  };

  const constructChat = (events) => {
    const bostaLogs = constructBostaMessages(events);
    const consigneeLogs = mapConsigneeMessages(events);
    return [...bostaLogs, ...consigneeLogs].sort((a, b) =>
      dayjs(a.timeStamp).diff(dayjs(b.timeStamp))
    );
  };

  const mapConsigneeMessages = (events) => {
    const consgineeEventsLog = constructConsgineeEventsMessages(events);
    const locationTimeStamp = getLocationTimeStamp(events);
    const consigneeLogs = [...consgineeEventsLog];
    const { consigneeConfirmedDelivery, reschedule, cancel, changeLocation } =
      whatsAppLastMileActions || {};
    if (consigneeConfirmedDelivery) {
      const { isConfirmedDelivery, confirmedGeoLocation } =
        consigneeConfirmedDelivery;

      if (isConfirmedDelivery) {
        if (confirmedGeoLocation) {
          consigneeLogs.push({
            messageType: CONSIGNEE_MESSAGE,
            timeStamp: locationTimeStamp,
            message: mapLink(confirmedGeoLocation[0], confirmedGeoLocation[1])
          });
        }
      }
    }
    if (reschedule) {
      consigneeLogs.push({
        messageType: CONSIGNEE_MESSAGE,
        timeStamp: dayjs(reschedule?.rescheduledAt),
        message: intl.formatMessage(
          {
            id: 'deliveries.delivery_details.whats_app.order_rescheduled'
          },
          {
            date: dayjs(reschedule?.rescheduledTo)?.format(
              WHATS_APP_RESCHUDLE_FORMAT
            )
          }
        )
      });
    }
    if (cancel) {
      consigneeLogs.push({
        messageType: CONSIGNEE_MESSAGE,
        timeStamp: dayjs(cancel?.cancelledAt),
        message: intl.formatMessage(
          {
            id: 'deliveries.delivery_details.whats_app.order_canceled'
          },
          { reason: cancel?.cancellationReason }
        )
      });
    }
    if (changeLocation) {
      consigneeLogs.push({
        messageType: CONSIGNEE_MESSAGE,
        timeStamp: dayjs(changeLocation?.changedAt),
        message: intl.formatMessage(
          {
            id: 'deliveries.delivery_details.whats_app.sending_location'
          },
          { location: changeLocation?.locationChangedTo }
        )
      });
    }
    return consigneeLogs;
  };

  return (
    <div className="chat-wrapper">
      {allLogs?.map((log) => {
        return (
          <div
            className={classNames('message-wrapper', {
              'bosta-message-wrapper': log.messageType === BOSTA_MESSAGE,
              'consignee-message-wrapper': log.messageType === CONSIGNEE_MESSAGE
            })}
          >
            {log.messageType === BOSTA_MESSAGE && (
              <span className="logo-wrapper">
                <Bosta />
              </span>
            )}
            <div className="message-content">
              <span className="body">{log?.message}</span>
              <span className="time-stamp caption">
                {log?.timeStamp?.format(WHATS_APP_DATE_FORMAT)}
                {log?.status && <span>{renderSentIcon(log?.status)}</span>}
              </span>
            </div>
            {log.messageType === CONSIGNEE_MESSAGE && (
              <span className="logo-wrapper">
                <User />
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WhatsAppLogs;
